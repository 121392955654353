<template>
  <div v-if="!authentificated">
    fehlende Berechtigung
    <!-- TODO: team auswählen in welchem Team als Kunde erstellt werden soll -->
  </div>
  <b-form v-else @submit.stop.prevent="handleSubmit">
    <b-row>
      <b-col cols="4">
        <b-form-group
          label="Name"
          :state="error | FGErrorFieldCheck('name')"
          :invalid-feedback="error | FGErrorFieldInvalidFeedback('name')"
          label-size="sm"
        >
          <b-form-input name="name" required placeholder="Name" size="sm" />
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group
          label="Kundennummer"
          :state="error | FGErrorFieldCheck('internal_customer_id')"
          :invalid-feedback="
            error | FGErrorFieldInvalidFeedback('internal_customer_id')
          "
          label-size="sm"
        >
          <b-form-input
            v-if="$store.getters.backendUserData.isAdmin"
            name="internal_customer_id"
            placeholder="Kundennummer"
            size="sm"
          />
          <small v-else><em>wird durch TCO vergeben</em></small>
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group
          label="Vertriebspartnernummer"
          :state="error | FGErrorFieldCheck('internal_sales_id')"
          :invalid-feedback="
            error | FGErrorFieldInvalidFeedback('internal_sales_id')
          "
          label-size="sm"
        >
          <b-form-input
            v-if="$store.getters.backendUserData.isAdmin"
            name="internal_sales_id"
            placeholder="Vertriebspartnernummer"
            size="sm"
          />
          <small v-else><em>wird durch TCO vergeben</em></small>
        </b-form-group>
      </b-col>
    </b-row>
    <b-form-group label="Anschrift">
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Straße & Haus-Nr"
            :state="error | FGErrorFieldCheck('street_line_1')"
            :invalid-feedback="
              error | FGErrorFieldInvalidFeedback('street_line_1')
            "
            label-size="sm"
          >
            <b-form-input name="street_line_1" required size="sm" />
          </b-form-group>
        </b-col>
        <!-- street_line, sollte immer nur verwendet werden, wenn unbedingt gebraucht -->
        <!-- <b-col cols="12">
          <b-form-group
            label="Haus-Nr"
            :state="error | FGErrorFieldCheck('street_line_2')"
            :invalid-feedback="error | FGErrorFieldInvalidFeedback('street_line_2')"
            label-size="sm"
          >
            <b-form-input name="street_line_2" required size="sm" />
          </b-form-group>
        </b-col> -->
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group
            label="PLZ"
            :state="error | FGErrorFieldCheck('zipCode')"
            :invalid-feedback="error | FGErrorFieldInvalidFeedback('zipCode')"
            label-size="sm"
          >
            <b-form-input name="zipCode" required size="sm" />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Ort"
            :state="error | FGErrorFieldCheck('city')"
            :invalid-feedback="error | FGErrorFieldInvalidFeedback('city')"
            label-size="sm"
          >
            <b-form-input name="city" required size="sm" />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form-group>
    <b-form-group label="Handelsregister">
      <b-row>
        <b-col cols="3">
          <b-form-group
            label="HR-Nummer"
            :state="error | FGErrorFieldCheck('hr_nr')"
            :invalid-feedback="error | FGErrorFieldInvalidFeedback('hr_nr')"
            label-size="sm"
          >
            <b-form-input name="hr_nr" size="sm" />
          </b-form-group>
        </b-col>
        <b-col cols="5">
          <b-form-group
            label="Ort des Handelsregisters"
            :state="error | FGErrorFieldCheck('hr_city')"
            :invalid-feedback="error | FGErrorFieldInvalidFeedback('hr_city')"
            label-size="sm"
          >
            <b-form-input name="hr_city" size="sm" />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
            label="Art des Handelsregisters"
            :state="error | FGErrorFieldCheck('hr')"
            :invalid-feedback="error | FGErrorFieldInvalidFeedback('hr')"
            label-size="sm"
          >
            <b-form-select name="hr" :options="hr_options" size="sm" />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form-group>
    <b-card no-body class="mb-3">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button v-b-toggle.accordion-contact block variant="light" size="sm">
          Weitere Kontakinformationen
        </b-button>
      </b-card-header>
      <b-collapse
        id="accordion-contact"
        ref="accordion-contact"
        accordion="accordion-team"
        role="tabpanel"
      >
        <b-card-body>
          <b-row>
            <b-col cols="12" md="4">
              <b-form-group
                label="Telefon"
                :state="error | FGErrorFieldCheck('phone')"
                :invalid-feedback="error | FGErrorFieldInvalidFeedback('phone')"
              >
                <b-form-input name="phone" size="sm" />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-group
                label="Mobil"
                :state="error | FGErrorFieldCheck('mobile_phone')"
                :invalid-feedback="
                  error | FGErrorFieldInvalidFeedback('mobile_phone')
                "
              >
                <b-form-input name="mobile_phone" size="sm" />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-group
                label="Fax"
                :state="error | FGErrorFieldCheck('fax')"
                :invalid-feedback="error | FGErrorFieldInvalidFeedback('fax')"
              >
                <b-form-input name="fax" size="sm" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6">
              <b-form-group
                label="E-Mail"
                :state="error | FGErrorFieldCheck('email')"
                :invalid-feedback="error | FGErrorFieldInvalidFeedback('email')"
              >
                <b-form-input name="email" size="sm" />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group
                label="Webseite"
                :state="error | FGErrorFieldCheck('website')"
                :invalid-feedback="
                  error | FGErrorFieldInvalidFeedback('website')
                "
              >
                <b-form-input name="website" size="sm" />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body class="mb-5">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button v-b-toggle.accordion-billing block variant="light" size="sm">
          Rechnungs- & Zahlungsinformationen
        </b-button>
      </b-card-header>
      <b-collapse
        id="accordion-billing"
        ref="accordion-billing"
        accordion="accordion-team"
        role="tabpanel"
      >
        <b-card-body>
          <b-row>
            <b-col cols="8">
              <b-form-group
                label="IBAN"
                :state="error | FGErrorFieldCheck('iban')"
                :invalid-feedback="error | FGErrorFieldInvalidFeedback('iban')"
              >
                <b-form-input name="iban" size="sm" />
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group
                label="BIC"
                :state="error | FGErrorFieldCheck('bic')"
                :invalid-feedback="error | FGErrorFieldInvalidFeedback('bic')"
              >
                <b-form-input name="bic" size="sm" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="Name der Bank"
                :state="error | FGErrorFieldCheck('bank')"
                :invalid-feedback="error | FGErrorFieldInvalidFeedback('bank')"
              >
                <b-form-input name="bank" size="sm" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="Umsatzsteuer-Identifikationsnummer"
                :state="error | FGErrorFieldCheck('ust_id')"
                :invalid-feedback="
                  error | FGErrorFieldInvalidFeedback('ust_id')
                "
              >
                <b-form-input name="ust_id" size="sm" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="Rechnungsemail"
                :state="error | FGErrorFieldCheck('bill_email')"
                :invalid-feedback="
                  error | FGErrorFieldInvalidFeedback('bill_email')
                "
              >
                <b-form-input
                  name="bill_email"
                  type="email"
                  size="sm"
                  placeholder="E-Mail"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-form-group label="Rechnungsaddresse">
            <b-row>
              <b-col cols="8">
                <b-form-group
                  label="Straße"
                  :state="error | FGErrorFieldCheck('bill_street_line_1')"
                  :invalid-feedback="
                    error | FGErrorFieldInvalidFeedback('bill_street_line_1')
                  "
                >
                  <b-form-input
                    name="bill_street_line_1"
                    placeholder="Straße"
                    size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group
                  v-if="false"
                  label="Straße (2.Zeile)"
                  :state="error | FGErrorFieldCheck('bill_street_line_2')"
                  :invalid-feedback="
                    error | FGErrorFieldInvalidFeedback('bill_street_line_2')
                  "
                >
                  <b-form-input
                    name="bill_street_line_2"
                    placeholder="Straße (2.Zeile)"
                    size="sm"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">
                <b-form-group
                  label="PLZ"
                  :state="error | FGErrorFieldCheck('bill_zipCode')"
                  :invalid-feedback="
                    error | FGErrorFieldInvalidFeedback('bill_zipCode')
                  "
                >
                  <b-form-input
                    name="bill_zipCode"
                    placeholder="PLZ"
                    size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  label="Stadt"
                  :state="error | FGErrorFieldCheck('bill_city')"
                  :invalid-feedback="
                    error | FGErrorFieldInvalidFeedback('bill_city')
                  "
                >
                  <b-form-input
                    name="bill_city"
                    placeholder="Stadt"
                    size="sm"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form-group>
        </b-card-body>
      </b-collapse>
    </b-card>
    <b-button
      type="submit"
      block
      variant="outline-primary"
      class="mt-2"
      size="sm"
      :disabled="loading"
    >
      {{ custom_create_new_text }}
    </b-button>
  </b-form>
</template>

<script>
import hr_options from '@/data/hr_options';
export default {
  name: 'TeamNew',
  props: {
    team_id: {
      type: String,
      default: null,
    },
    team_roles: {
      type: Array,
      default: null,
    },
    custom_create_new_text: {
      type: String,
      default: 'Neues Team erstellen',
    },
  },
  data() {
    return {
      // Form Submit
      loading: false,
      error: null,
      // Data
      email: null,
      hr_options,
    };
  },
  computed: {
    authentificated() {
      return (
        (this.$store.getters.backendUserData &&
          this.$store.getters.backendUserData.isAdmin) ||
        (this.team_id && this.team_roles.includes('CUSTOMER'))
      );
    },
  },
  created() {
    if ((this.team_id || this.team_roles) && !(this.team_id && this.team_roles))
      throw new Error(
        'Please provide eigher no parameter of or both of team_id & team_role',
      );
  },
  methods: {
    // TODO: check if the article prices are overwritten by default
    async handleSubmit(ev) {
      this.loading = true;
      const data = Object.fromEntries(new FormData(ev.target));
      let team = await this.$api
        .post(`/team`, {
          ...Object.keys(data).reduce(
            (out, name) => ({
              ...out,
              [name]:
                typeof data[name] == 'string' ? data[name] || null : data[name],
            }),
            {},
          ),
          team_id: this.team_id || undefined,
          team_roles: this.team_roles || undefined,
        })
        .then((res) => {
          this.error = null;
          return res.data;
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.type)
            this.error = err.response.data;
          throw err;
        })
        .finally(() => {
          this.loading = false;
        });
      this.$emit('created', team);
    },
  },
};
</script>

<style></style>
