import axios from 'axios';

// All additional defaults, like URL and Token(Authorization-Header) will be set in Vuex index.js
const API = {
  install(Vue) {
    Vue.prototype.$api = axios.create({
      responseType: 'json',
      baseURL:
        process.env.NODE_ENV != 'production'
          ? 'http://localhost:3000/api'
          : '/api/',
    });
  },
};

export default API;
