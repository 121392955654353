<template>
  <div>
    <h3>Account nicht aktiv</h3>
    <p>
      Dieser Account ist derzeit nicht aktiv, da Sie keinen Zugriff auf einen
      Teambereich haben.
    </p>
    <h5>Wie erhalte ich Zugriff?</h5>
    <p>
      Bitte kontaktieren Sie uns unter
      <b-link
        :href="`mailto:${
          system_data.main_team.email ||
          system_data.main_team.sales_email ||
          system_data.main_team.bill_email
        }?subject=Kein%20Zugriff%20auf%20Teambereich&body=Guten%20Tag%2C%0Af%C3%BCr%20den%20Nutzeraccount%20mit%20der%20E-Mail%3A%20${
          $store.getters.currentUser.email
        }%20fehlt%20aktuell%20der%20Zugang%20zu%20einem%20Teambereich.%0A%0AIch%20bitte%20Sie%2C%20dieses%20Anliegen%20zu%20pr%C3%BCfen%20und%20sich%20im%20Anschluss%20zur%C3%BCckzumelden.%0A%0AMit%20freundlichen%20Gr%C3%BC%C3%9Fen%0AIHR%20NAME`"
      >
        {{
          system_data.main_team.email ||
          system_data.main_team.sales_email ||
          system_data.main_team.bill_email
        }}
      </b-link>
      mit Angabe der E-Mail Adresse Ihres Accounts um Ihren Zugang zu überprüfen
      und diesen Account einem Teambereich zuzuordnen.
    </p>
    <b-link @click="$store.dispatch('updateTeamState')"> Aktualisieren </b-link>
    <br />
    <b-link @click="$router.push({ name: 'support' })"> Support </b-link>
    <br />
    <b-link @click="$store.dispatch('logout')"> Abmelden </b-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['system_data']),
  },
};
</script>
