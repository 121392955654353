<template>
  <div>
    <b-row class="justify-content-center">
      <b-col cols="12" md="8">
        <b-form class="mb-4" @submit.stop.prevent="handleSubmit">
          <b-form-group label="E-Mail" label-size="sm">
            <b-form-input
              name="email"
              required
              autocomplete="username"
              size="sm"
            />
          </b-form-group>
          <b-form-group label="Passwort" label-size="sm">
            <b-form-input
              name="password"
              type="password"
              required
              autocomplete="current-password"
              size="sm"
            />
          </b-form-group>
          <b-button block type="submit" variant="primary"> Login </b-button>
          <div v-if="wrongCredentials" class="text-danger text-center">
            Email oder Passwort ungültig.
          </div>
          <div class="d-flex">
            <b-link v-b-modal.password-reset> Passwort zurücksetzen </b-link>
          </div>
        </b-form>
      </b-col>
    </b-row>
    <b-modal
      id="password-reset"
      ref="reset-password-modal"
      title="Passwort zurücksetzen"
      hide-footer
      @show="wrongResetEmail = false"
    >
      <b-form
        ref="reset-password-form"
        @submit.stop.prevent="handleSubmitResetPW"
      >
        <b-form-group label="E-Mail">
          <b-form-input name="email" type="email" required />
        </b-form-group>
        <div class="small mb-3">
          Wenn Sie Ihr Passwort vergessen haben, können Sie hier die
          Zurücksetzung Ihres Zugangs beantragen. Sie erhalten dann in Kürze von
          uns eine E-Mail. Bitte sichten Sie auch den Spam-Ordner.
        </div>
        <b-button block variant="outline-secondary" type="submit">
          Passwort zurücksetzen
        </b-button>
        <div v-if="wrongResetEmail" class="text-danger text-center mt-2">
          Die E-Mail ist nicht im System registriert.
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'LoginComponent',
  data() {
    return {
      wrongCredentials: false,
      wrongResetEmail: false,
    };
  },
  methods: {
    handleSubmit(ev) {
      this.wrongCredentials = false;
      return this.$store
        .dispatch('login', Object.fromEntries(new FormData(ev.target)))
        .catch((err) => {
          if (
            [
              'auth/invalid-email',
              'auth/user-not-found',
              'auth/wrong-password',
            ].includes(err.code)
          ) {
            this.wrongCredentials = true;
            return;
          }
          throw err;
        });
    },
    handleSubmitResetPW(ev) {
      this.wrongResetEmail = false;
      return this.$store
        .dispatch(
          'resetPWbyEmail',
          Object.fromEntries(new FormData(ev.target)).email,
        )
        .then(() => {
          this.$refs['reset-password-form'].reset();
          this.$refs['reset-password-modal'].hide();
        })
        .catch((err) => {
          if (
            ['auth/invalid-email', 'auth/user-not-found'].includes(err.code)
          ) {
            this.wrongResetEmail = true;
            return;
          }
          throw err;
        });
    },
  },
};
</script>
