<template>
  <div id="app">
    <Loader v-if="!$store.state.loaded" />
    <Setup v-else-if="!system_data || !system_data.main_team" />
    <template
      v-else-if="
        !$store.state.currentUser ||
        !backendUserData ||
        (!backendUserData.isAdmin &&
          !(
            backendUserData.team_persons && backendUserData.team_persons.length
          ))
      "
    >
      <b-navbar type="dark" class="border-bottom mb-3 font-weight-bold">
        <b-navbar-brand :to="{ name: 'dashboard' }">
          <img
            :src="$store.state.designProperties.logo"
            alt="Logo"
            class="nav-logo"
          />
        </b-navbar-brand>

        <b-navbar-nav>
          <b-nav-item
            :to="{ name: 'support' }"
            left
            :active="$route.name.includes('support')"
            class="d-none d-lg-block"
          >
            Support
          </b-nav-item>
          <b-nav-item
            :to="{ name: 'datenschutz' }"
            left
            :active="$route.name.includes('datenschutz')"
            class="d-none d-lg-block"
          >
            Datenschutz
          </b-nav-item>
        </b-navbar-nav>
      </b-navbar>
      <b-container fluid>
        <b-row
          v-if="['support', 'datenschutz'].includes($route.name)"
          class="justify-content-center mb-5"
        >
          <b-col cols="12" md="10" class="p-1 p-md-2">
            <router-view class="mx-3" />
          </b-col>
        </b-row>
        <b-row
          v-else-if="
            backendUserData &&
            !backendUserData.isAdmin &&
            !(
              backendUserData.team_persons &&
              backendUserData.team_persons.length
            )
          "
          class="justify-content-center mb-5"
        >
          <b-col cols="12" md="10" class="p-1 p-md-2">
            <AccountInactive />
          </b-col>
        </b-row>
        <Login v-else />
      </b-container>
    </template>
    <template v-else>
      <b-navbar
        type="dark"
        class="border-bottom mb-3 font-weight-bold"
        toggleable="md"
      >
        <b-navbar-brand :to="{ name: 'dashboard' }">
          <img
            :src="$store.state.designProperties.logo"
            alt="Logo"
            class="nav-logo"
          />
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse" />

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-item
              :to="{ name: 'calendar' }"
              left
              :active="$route.name.includes('calendar')"
            >
              <b-icon-calendar />
            </b-nav-item>
            <b-nav-item
              :to="{ name: 'info' }"
              left
              :active="$route.name.includes('info')"
            >
              Info/News
            </b-nav-item>
            <b-nav-item
              v-if="activeTeam.internal_customer_id"
              :to="{ name: 'activeTeam_projects_as_customer' }"
              left
              :active="$route.name.includes('activeTeam_projects_as_customer')"
            >
              Angebote/Aufträge
            </b-nav-item>
            <b-nav-item
              v-if="
                ($store.getters.activeTeam_customers &&
                  $store.getters.activeTeam_customers.length) ||
                ($store.getters.activeTeam &&
                  $store.getters.activeTeam.internal_sales_id)
              "
              :to="{ name: 'teams_as_customers' }"
              left
              :active="$route.name == 'teams_as_customers'"
            >
              Kunden
            </b-nav-item>
            <b-nav-item
              :to="{ name: 'products' }"
              left
              :active="$route.name.includes('product')"
            >
              Produkte
            </b-nav-item>
            <b-nav-item
              v-if="
                ($store.getters.activeTeam_customers &&
                  $store.getters.activeTeam_customers.length) ||
                ($store.getters.activeTeam &&
                  $store.getters.activeTeam.internal_sales_id)
              "
              :to="{ name: 'activeTeam_projects_as_assigned_team' }"
              left
              :active="$route.name == 'activeTeam_projects_as_assigned_team'"
            >
              Projekte
            </b-nav-item>
            <b-nav-item-dropdown
              text="Weiteres"
              right
              class="d-none d-md-block d-lg-none"
            >
              <b-dropdown-item :to="{ name: 'support' }">
                Support
              </b-dropdown-item>
              <b-dropdown-item :to="{ name: 'datenschutz' }">
                Datenschutz
              </b-dropdown-item>
              <b-dropdown-item
                v-if="backendUserData.isAdmin"
                :to="{ name: 'admin' }"
              >
                Adminbereich
              </b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item
              :to="{ name: 'support' }"
              left
              :active="$route.name.includes('support')"
              class="d-md-none d-lg-block"
            >
              Support
            </b-nav-item>
            <b-nav-item
              :to="{ name: 'datenschutz' }"
              left
              :active="$route.name.includes('datenschutz')"
              class="d-md-none d-lg-block"
            >
              Datenschutz
            </b-nav-item>
            <b-nav-item
              v-if="backendUserData.isAdmin"
              :to="{ name: 'admin' }"
              left
              :active="$route.name.includes('admin')"
              class="d-md-none d-lg-block"
            >
              Adminbereich
            </b-nav-item>
          </b-navbar-nav>

          <b-navbar-nav class="ml-auto">
            <b-nav-item right class="d-none d-md-block">
              <b-button
                v-b-modal.settings
                variant="link"
                class="text-light px-1"
              >
                <b-icon icon="gear-fill" />
              </b-button>
            </b-nav-item>
            <b-nav-item v-b-modal.settings right class="mt-2 d-md-none">
              Einstellungen
            </b-nav-item>
            <b-nav-item right>
              <b-button
                variant="link"
                class="text-danger font-weight-bold px-0"
                @click="$store.dispatch('logout')"
              >
                Ausloggen
              </b-button>
            </b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
      <b-container fluid>
        <b-row
          v-if="
            $store.getters.backendUserData.isAdmin &&
            $store.getters.backendUserData.team_persons.length == 0
          "
          class="justify-content-center"
        >
          <b-col cols="12" md="10" class="bg-warning text-white">
            <strong>Hinweis: </strong>Sie sind als Admin angemeldet, jedoch
            aktuell keinem Team zugeordnet.
            <br />
            Um auf einen Teambereich zugreifen zu können, müssen Sie sich selbst
            einem Partner als Person mit Ihrer E-Mail zuweisen, den Account
            aktivieren und die Webseite erneut laden.
          </b-col>
        </b-row>
        <b-row class="justify-content-center mb-5">
          <b-col cols="12" md="10" class="p-1 p-md-2">
            <router-view class="mx-3" />
          </b-col>
        </b-row>
      </b-container>
      <b-modal id="settings" title="Einstellungen" size="xl" hide-footer>
        <b-row>
          <b-col cols="4" class="pl-0">
            <b-list-group flush>
              <b-list-group-item
                :active="settingsView === 'calendar'"
                @click="settingsView = 'calendar'"
              >
                Kalendereinstellungen
              </b-list-group-item>
              <b-list-group-item
                :active="settingsView === 'change_pw'"
                @click="settingsView = 'change_pw'"
              >
                Passwort ändern
              </b-list-group-item>
              <b-list-group-item
                v-if="activeTeam"
                button
                @click="
                  () => {
                    $bvModal.hide('settings');
                    if ($route.name != 'dashboard')
                      $router.push({ name: 'dashboard' });
                    $nextTick(() =>
                      $router.push({
                        name: 'team',
                        params: { id: activeTeam.id },
                      }),
                    );
                  }
                "
              >
                Teamdaten
              </b-list-group-item>
              <b-list-group-item
                button
                class="text-danger"
                @click="$store.dispatch('logout')"
              >
                Ausloggen
              </b-list-group-item>
            </b-list-group>
          </b-col>
          <b-col v-if="settingsView === 'calendar'">
            <h4>Kalendereinstellungen</h4>
            <b-button
              v-if="!current_calendar_url"
              class="mb-2"
              size="sm"
              @click="displayActiveTeamCalendarUrl"
            >
              Kalender-URL anzeigen
            </b-button>
            <b-form-group
              v-else
              label="Öffentliche Kalender-URL für aktuelles Team"
            >
              <b-form-input
                :value="current_calendar_url"
                readonly
              ></b-form-input>
            </b-form-group>
            <b-form @submit.prevent="handleChangeCalendarSubmit">
              <b-form-group label="Kalender-Import URL" label-size="sm">
                <b-form-input
                  name="calendar_import_url"
                  :value="
                    backendUserData.settings &&
                    backendUserData.settings.calendar_import_urls &&
                    backendUserData.settings.calendar_import_urls[0]
                      ? backendUserData.settings.calendar_import_urls[0]
                      : ''
                  "
                  size="sm"
                />
              </b-form-group>
              <b-button type="submit" variant="outline-secondary" size="sm">
                Speichern
              </b-button>
              <strong
                v-if="change_calendar_status === true"
                class="ml-2 text-success"
              >
                Einstellungen wurden gespeichert!
              </strong>
              <strong
                v-else-if="change_calendar_status"
                class="ml-2 text-danger"
              >
                {{ change_calendar_status }}
              </strong>
            </b-form>
          </b-col>
          <b-col v-if="settingsView === 'change_pw'">
            <h4>Passwort ändern</h4>
            <b-form @submit.prevent="handleChangePWSubmit">
              <b-form-group label="Altes Passwort" label-size="sm">
                <b-form-input
                  name="current_password"
                  type="password"
                  required
                  autocomplete="current-password"
                  size="sm"
                />
              </b-form-group>
              <b-form-group label="Neues Passwort" label-size="sm">
                <b-form-input
                  name="password"
                  type="password"
                  required
                  autocomplete="new-password"
                  size="sm"
                />
              </b-form-group>
              <b-form-group label="Passwortbestätigung" label-size="sm">
                <b-form-input
                  name="confirm_password"
                  type="password"
                  required
                  autocomplete="new-password"
                  size="sm"
                />
              </b-form-group>
              <b-button type="submit" variant="outline-secondary" size="sm">
                Passwort ändern
              </b-button>
              <strong
                v-if="change_pw_status === true"
                class="ml-2 text-success"
              >
                Das Passwort wurde erfolgreich geändert!
              </strong>
              <strong v-else-if="change_pw_status" class="ml-2 text-danger">
                {{ change_pw_status }}
              </strong>
            </b-form>
          </b-col>
        </b-row>
      </b-modal>
    </template>
  </div>
</template>

<script>
import Login from '@/views/Login.vue';
import Setup from '@/views/Setup.vue';
import Loader from './components/Loader.vue';
import { mapGetters } from 'vuex';
import AccountInactive from './views/AccountInactive.vue';

export default {
  components: {
    Login,
    Loader,
    Setup,
    AccountInactive,
  },
  data() {
    return {
      settingsView: 'calendar',
      change_calendar_status: null,
      current_calendar_url: null,
      change_pw_status: null,
    };
  },
  computed: {
    ...mapGetters(['system_data', 'backendUserData', 'activeTeam']),
  },
  watch: {
    activeTeam: {
      deep: true,
      handler(val, oldval) {
        if (val && oldval && val.id === oldval.id) return;
        this.current_calendar_url = null;
      },
    },
  },
  async created() {
    await this.$store.dispatch('startup');
  },
  methods: {
    async displayActiveTeamCalendarUrl() {
      const api_key = await this.$api
        .get('/user/me/calendar-api-key')
        .then((res) => res.data);

      this.current_calendar_url =
        location.protocol +
        '//' +
        location.hostname +
        `/api/team/${this.activeTeam.id}/calendar.ics?api_key=${api_key}`;
    },
    async handleChangeCalendarSubmit(ev) {
      let data = Object.fromEntries(new FormData(ev.target));
      let body = {
        settings: {
          calendar_import_urls:
            this.backendUserData.settings &&
            this.backendUserData.settings.calendar_import_urls
              ? this.backendUserData.settings.calendar_import_urls
              : [],
        },
      };
      body.settings.calendar_import_urls[0] = data.calendar_import_url;
      body.settings.calendar_import_urls =
        body.settings.calendar_import_urls.filter((e) => !!e);

      this.change_calendar_status = null;
      this.$api.patch(`/user/me`, body);
      this.change_calendar_status = true;
    },
    async handleChangePWSubmit(ev) {
      let data = Object.fromEntries(new FormData(ev.target));
      if (data.password == data.current_password)
        return (this.change_pw_status =
          'Altes Passwort und neues Passwort sind identisch.');
      if (data.password != data.confirm_password)
        return (this.change_pw_status =
          'Die neuen Passwörter stimmen nicht überein.');
      else this.change_pw_status = null;
      this.$store.dispatch('changePW', {
        old_password: data.current_password,
        new_password: data.password,
      });
      this.change_pw_status = true;
      ev.target.reset();
    },
  },
};
</script>

<style>
@media (min-width: 768px) {
  .navbar {
    padding-left: 9.8% !important;
    padding-right: 9.8% !important;
  }
}

.nav-logo {
  height: 3rem;
}
</style>

<style lang="scss">
@import 'styles/_custom.scss';
</style>
