var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[(!_vm.$store.state.loaded)?_c('Loader'):(!_vm.system_data || !_vm.system_data.main_team)?_c('Setup'):(
      !_vm.$store.state.currentUser ||
      !_vm.backendUserData ||
      (!_vm.backendUserData.isAdmin &&
        !(
          _vm.backendUserData.team_persons && _vm.backendUserData.team_persons.length
        ))
    )?[_c('b-navbar',{staticClass:"border-bottom mb-3 font-weight-bold",attrs:{"type":"dark"}},[_c('b-navbar-brand',{attrs:{"to":{ name: 'dashboard' }}},[_c('img',{staticClass:"nav-logo",attrs:{"src":_vm.$store.state.designProperties.logo,"alt":"Logo"}})]),_c('b-navbar-nav',[_c('b-nav-item',{staticClass:"d-none d-lg-block",attrs:{"to":{ name: 'support' },"left":"","active":_vm.$route.name.includes('support')}},[_vm._v(" Support ")]),_c('b-nav-item',{staticClass:"d-none d-lg-block",attrs:{"to":{ name: 'datenschutz' },"left":"","active":_vm.$route.name.includes('datenschutz')}},[_vm._v(" Datenschutz ")])],1)],1),_c('b-container',{attrs:{"fluid":""}},[(['support', 'datenschutz'].includes(_vm.$route.name))?_c('b-row',{staticClass:"justify-content-center mb-5"},[_c('b-col',{staticClass:"p-1 p-md-2",attrs:{"cols":"12","md":"10"}},[_c('router-view',{staticClass:"mx-3"})],1)],1):(
          _vm.backendUserData &&
          !_vm.backendUserData.isAdmin &&
          !(
            _vm.backendUserData.team_persons &&
            _vm.backendUserData.team_persons.length
          )
        )?_c('b-row',{staticClass:"justify-content-center mb-5"},[_c('b-col',{staticClass:"p-1 p-md-2",attrs:{"cols":"12","md":"10"}},[_c('AccountInactive')],1)],1):_c('Login')],1)]:[_c('b-navbar',{staticClass:"border-bottom mb-3 font-weight-bold",attrs:{"type":"dark","toggleable":"md"}},[_c('b-navbar-brand',{attrs:{"to":{ name: 'dashboard' }}},[_c('img',{staticClass:"nav-logo",attrs:{"src":_vm.$store.state.designProperties.logo,"alt":"Logo"}})]),_c('b-navbar-toggle',{attrs:{"target":"nav-collapse"}}),_c('b-collapse',{attrs:{"id":"nav-collapse","is-nav":""}},[_c('b-navbar-nav',[_c('b-nav-item',{attrs:{"to":{ name: 'calendar' },"left":"","active":_vm.$route.name.includes('calendar')}},[_c('b-icon-calendar')],1),_c('b-nav-item',{attrs:{"to":{ name: 'info' },"left":"","active":_vm.$route.name.includes('info')}},[_vm._v(" Info/News ")]),(_vm.activeTeam.internal_customer_id)?_c('b-nav-item',{attrs:{"to":{ name: 'activeTeam_projects_as_customer' },"left":"","active":_vm.$route.name.includes('activeTeam_projects_as_customer')}},[_vm._v(" Angebote/Aufträge ")]):_vm._e(),(
              (_vm.$store.getters.activeTeam_customers &&
                _vm.$store.getters.activeTeam_customers.length) ||
              (_vm.$store.getters.activeTeam &&
                _vm.$store.getters.activeTeam.internal_sales_id)
            )?_c('b-nav-item',{attrs:{"to":{ name: 'teams_as_customers' },"left":"","active":_vm.$route.name == 'teams_as_customers'}},[_vm._v(" Kunden ")]):_vm._e(),_c('b-nav-item',{attrs:{"to":{ name: 'products' },"left":"","active":_vm.$route.name.includes('product')}},[_vm._v(" Produkte ")]),(
              (_vm.$store.getters.activeTeam_customers &&
                _vm.$store.getters.activeTeam_customers.length) ||
              (_vm.$store.getters.activeTeam &&
                _vm.$store.getters.activeTeam.internal_sales_id)
            )?_c('b-nav-item',{attrs:{"to":{ name: 'activeTeam_projects_as_assigned_team' },"left":"","active":_vm.$route.name == 'activeTeam_projects_as_assigned_team'}},[_vm._v(" Projekte ")]):_vm._e(),_c('b-nav-item-dropdown',{staticClass:"d-none d-md-block d-lg-none",attrs:{"text":"Weiteres","right":""}},[_c('b-dropdown-item',{attrs:{"to":{ name: 'support' }}},[_vm._v(" Support ")]),_c('b-dropdown-item',{attrs:{"to":{ name: 'datenschutz' }}},[_vm._v(" Datenschutz ")]),(_vm.backendUserData.isAdmin)?_c('b-dropdown-item',{attrs:{"to":{ name: 'admin' }}},[_vm._v(" Adminbereich ")]):_vm._e()],1),_c('b-nav-item',{staticClass:"d-md-none d-lg-block",attrs:{"to":{ name: 'support' },"left":"","active":_vm.$route.name.includes('support')}},[_vm._v(" Support ")]),_c('b-nav-item',{staticClass:"d-md-none d-lg-block",attrs:{"to":{ name: 'datenschutz' },"left":"","active":_vm.$route.name.includes('datenschutz')}},[_vm._v(" Datenschutz ")]),(_vm.backendUserData.isAdmin)?_c('b-nav-item',{staticClass:"d-md-none d-lg-block",attrs:{"to":{ name: 'admin' },"left":"","active":_vm.$route.name.includes('admin')}},[_vm._v(" Adminbereich ")]):_vm._e()],1),_c('b-navbar-nav',{staticClass:"ml-auto"},[_c('b-nav-item',{staticClass:"d-none d-md-block",attrs:{"right":""}},[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.settings",modifiers:{"settings":true}}],staticClass:"text-light px-1",attrs:{"variant":"link"}},[_c('b-icon',{attrs:{"icon":"gear-fill"}})],1)],1),_c('b-nav-item',{directives:[{name:"b-modal",rawName:"v-b-modal.settings",modifiers:{"settings":true}}],staticClass:"mt-2 d-md-none",attrs:{"right":""}},[_vm._v(" Einstellungen ")]),_c('b-nav-item',{attrs:{"right":""}},[_c('b-button',{staticClass:"text-danger font-weight-bold px-0",attrs:{"variant":"link"},on:{"click":function($event){return _vm.$store.dispatch('logout')}}},[_vm._v(" Ausloggen ")])],1)],1)],1)],1),_c('b-container',{attrs:{"fluid":""}},[(
          _vm.$store.getters.backendUserData.isAdmin &&
          _vm.$store.getters.backendUserData.team_persons.length == 0
        )?_c('b-row',{staticClass:"justify-content-center"},[_c('b-col',{staticClass:"bg-warning text-white",attrs:{"cols":"12","md":"10"}},[_c('strong',[_vm._v("Hinweis: ")]),_vm._v("Sie sind als Admin angemeldet, jedoch aktuell keinem Team zugeordnet. "),_c('br'),_vm._v(" Um auf einen Teambereich zugreifen zu können, müssen Sie sich selbst einem Partner als Person mit Ihrer E-Mail zuweisen, den Account aktivieren und die Webseite erneut laden. ")])],1):_vm._e(),_c('b-row',{staticClass:"justify-content-center mb-5"},[_c('b-col',{staticClass:"p-1 p-md-2",attrs:{"cols":"12","md":"10"}},[_c('router-view',{staticClass:"mx-3"})],1)],1)],1),_c('b-modal',{attrs:{"id":"settings","title":"Einstellungen","size":"xl","hide-footer":""}},[_c('b-row',[_c('b-col',{staticClass:"pl-0",attrs:{"cols":"4"}},[_c('b-list-group',{attrs:{"flush":""}},[_c('b-list-group-item',{attrs:{"active":_vm.settingsView === 'calendar'},on:{"click":function($event){_vm.settingsView = 'calendar'}}},[_vm._v(" Kalendereinstellungen ")]),_c('b-list-group-item',{attrs:{"active":_vm.settingsView === 'change_pw'},on:{"click":function($event){_vm.settingsView = 'change_pw'}}},[_vm._v(" Passwort ändern ")]),(_vm.activeTeam)?_c('b-list-group-item',{attrs:{"button":""},on:{"click":() => {
                  _vm.$bvModal.hide('settings');
                  if (_vm.$route.name != 'dashboard')
                    _vm.$router.push({ name: 'dashboard' });
                  _vm.$nextTick(() =>
                    _vm.$router.push({
                      name: 'team',
                      params: { id: _vm.activeTeam.id },
                    }),
                  );
                }}},[_vm._v(" Teamdaten ")]):_vm._e(),_c('b-list-group-item',{staticClass:"text-danger",attrs:{"button":""},on:{"click":function($event){return _vm.$store.dispatch('logout')}}},[_vm._v(" Ausloggen ")])],1)],1),(_vm.settingsView === 'calendar')?_c('b-col',[_c('h4',[_vm._v("Kalendereinstellungen")]),(!_vm.current_calendar_url)?_c('b-button',{staticClass:"mb-2",attrs:{"size":"sm"},on:{"click":_vm.displayActiveTeamCalendarUrl}},[_vm._v(" Kalender-URL anzeigen ")]):_c('b-form-group',{attrs:{"label":"Öffentliche Kalender-URL für aktuelles Team"}},[_c('b-form-input',{attrs:{"value":_vm.current_calendar_url,"readonly":""}})],1),_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleChangeCalendarSubmit.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Kalender-Import URL","label-size":"sm"}},[_c('b-form-input',{attrs:{"name":"calendar_import_url","value":_vm.backendUserData.settings &&
                  _vm.backendUserData.settings.calendar_import_urls &&
                  _vm.backendUserData.settings.calendar_import_urls[0]
                    ? _vm.backendUserData.settings.calendar_import_urls[0]
                    : '',"size":"sm"}})],1),_c('b-button',{attrs:{"type":"submit","variant":"outline-secondary","size":"sm"}},[_vm._v(" Speichern ")]),(_vm.change_calendar_status === true)?_c('strong',{staticClass:"ml-2 text-success"},[_vm._v(" Einstellungen wurden gespeichert! ")]):(_vm.change_calendar_status)?_c('strong',{staticClass:"ml-2 text-danger"},[_vm._v(" "+_vm._s(_vm.change_calendar_status)+" ")]):_vm._e()],1)],1):_vm._e(),(_vm.settingsView === 'change_pw')?_c('b-col',[_c('h4',[_vm._v("Passwort ändern")]),_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleChangePWSubmit.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Altes Passwort","label-size":"sm"}},[_c('b-form-input',{attrs:{"name":"current_password","type":"password","required":"","autocomplete":"current-password","size":"sm"}})],1),_c('b-form-group',{attrs:{"label":"Neues Passwort","label-size":"sm"}},[_c('b-form-input',{attrs:{"name":"password","type":"password","required":"","autocomplete":"new-password","size":"sm"}})],1),_c('b-form-group',{attrs:{"label":"Passwortbestätigung","label-size":"sm"}},[_c('b-form-input',{attrs:{"name":"confirm_password","type":"password","required":"","autocomplete":"new-password","size":"sm"}})],1),_c('b-button',{attrs:{"type":"submit","variant":"outline-secondary","size":"sm"}},[_vm._v(" Passwort ändern ")]),(_vm.change_pw_status === true)?_c('strong',{staticClass:"ml-2 text-success"},[_vm._v(" Das Passwort wurde erfolgreich geändert! ")]):(_vm.change_pw_status)?_c('strong',{staticClass:"ml-2 text-danger"},[_vm._v(" "+_vm._s(_vm.change_pw_status)+" ")]):_vm._e()],1)],1):_vm._e()],1)],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }