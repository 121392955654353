import Vue from 'vue';
import Vuex from 'vuex';
import API from '@/plugins/api';
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signInWithEmailAndPassword,
  updatePassword,
  sendPasswordResetEmail,
  updateProfile,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from 'firebase/auth';
import firebase_config from '../../firebase_config.json';
const firebase_app = initializeApp(firebase_config);
const firebase_auth = getAuth(firebase_app);

Vue.use(Vuex);
Vue.use(API);

const app = new Vue();

/** HOW THE STATE SHOULD BE USED?!
 * the state saves the whole data about the Cataloge, which should be used everywhere in the app, to reduce api-load
 * it also implements a cache for a team-view session
 * TODO: for the admin-view sessions the api should be used directly with paging enabled
 */

export default new Vuex.Store({
  state: {
    loaded: false,
    app_status: null,
    system_data: null,
    accessToken: null,
    currentUser: null, // null-not loaded yet, false-noone is logged in, object is user
    backendUserData: null,
    updateIntervalId: null,
    // TeamState
    activeTeam_person: null,
    activeTeam: null,
    activeTeam_customers: null,
    // State
    products: null,
    product_groups: null,
    terms: null,
    articles: null,
    article_categories: null,
    article_companies: null,
    settings: null,
    files: null,
    designProperties:
      process.env.VUE_APP_DESIGN === 'TCO_CONNECT'
        ? {
            logo: '/logo-tco-connect.jpg',
          }
        : process.env.VUE_APP_DESIGN === 'TCO_ROBOTICS'
        ? {
            logo: '/logo-tco-robotics.png',
          }
        : undefined,
  },
  mutations: {
    activeTeam_person(state, activeTeam_person) {
      state.activeTeam_person = activeTeam_person;
      localStorage.setItem('selected_team_person_id', activeTeam_person.id);
    },
    resetTeamState(state) {
      localStorage.removeItem('selected_team_person_id');
      state.activeTeam_person = null;
      state.activeTeam = null;
      state.activeTeam_customers = null;
    },
  },
  actions: {
    async startup(context) {
      context.state.app_status = 'Warte auf Nutzerinitialisierung...';
      const system_data_promise = context.dispatch('updateSystemData');
      firebase_auth.onAuthStateChanged(async (user) => {
        if (user) {
          context.state.loaded = false;
          context.state.currentUser = user;
          context.state.accessToken = user.accessToken;
          app.$api.defaults.headers.common[
            'Authorization'
          ] = `Bearer ${user.accessToken}`;
          context.state.app_status = 'Lade Daten...';
          await Promise.all([
            system_data_promise,
            context.dispatch('updateTeamState'),
            context.dispatch('initApplicationState'),
          ]).catch((err) => {
            context.state.app_status =
              'Verbindungsfehler. Erneuter Versuch in 5 Sek...';
            console.log(
              'Verbindungsfehler: Es wird versucht aller 5 Sek. eine Verbindung herzustellen',
            );
            return new Promise((res) => {
              let interval_id = setInterval(() => {
                context.state.app_status = 'Lade Daten...';
                return context
                  .dispatch('updateSystemData')
                  .then(() =>
                    Promise.all([
                      context.dispatch('updateTeamState'),
                      context.dispatch('initApplicationState'),
                    ]),
                  )
                  .then(() => {
                    console.log('Verbindung konnte hergestellt werden');
                    clearInterval(interval_id);
                    res();
                  })
                  .catch((err) => {
                    console.log(err);
                    context.state.app_status =
                      'Verbindungsfehler. Erneuter Versuch in 5 Sek...';
                  });
              }, 7 * 1000);
            });
          });
          context.state.updateIntervalId = setInterval(
            () => context.dispatch('updateIntervalHandler'),
            5 * 60 * 1000,
          );
        } else {
          context.state.loaded = false;
          context.state.currentUser = false;
          context.state.backendUserData = false;
          context.state.accessToken = null;
          delete app.$api.defaults.headers.common['Authorization'];
          await system_data_promise.catch((err) => {
            context.state.app_status =
              'Verbindungsfehler. Erneuter Versuch in 5 Sek...';
            console.log(
              'Verbindungsfehler: Es wird versucht aller 5 Sek. eine Verbindung herzustellen',
            );
            return new Promise((res) => {
              let interval_id = setInterval(() => {
                context.state.app_status = 'Lade Daten...';
                return context
                  .dispatch('updateSystemData')
                  .then(() => {
                    console.log('Verbindung konnte hergestellt werden');
                    clearInterval(interval_id);
                    res();
                  })
                  .catch((err) => {
                    console.log(err);
                    context.state.app_status =
                      'Verbindungsfehler. Erneuter Versuch in 5 Sek...';
                  });
              }, 7 * 1000);
            });
          });
          context.commit('resetTeamState');
          clearInterval(context.state.updateIntervalId);
          context.state.updateIntervalId = null;
        }
        context.state.loaded = true;
        context.state.app_status = null;
      });
      firebase_auth.onIdTokenChanged((user) => {
        if (user) {
          context.state.accessToken = user.accessToken;
          app.$api.defaults.headers.common[
            'Authorization'
          ] = `Bearer ${user.accessToken}`;
        }
      });
      let retry_attempts = 0;
      app.$api.interceptors.response.use(
        function (response) {
          // Any status code that lie within the range of 2xx cause this function to trigger
          return response;
        },
        function (error) {
          // Any status codes that falls outside the range of 2xx cause this function to trigger
          // only 401 status codes results
          if (!error.response || error.response.status !== 401) {
            return Promise.reject(error);
          }
          console.log(
            'Der Token war ungültig oder ist abgelaufen. Es wird versucht die Session wiederherzustellen und den Request erneut auszuführen.',
          );
          const originalRequestConfig = error.config;
          delete originalRequestConfig.headers['Authorization']; // use from defaults

          // delay original requests until authorization has been completed
          if (retry_attempts > 10) {
            console.log('Zu viele Retries. Vorgang abgebrochen...');
            return Promise.reject(error);
          }
          retry_attempts++;
          setTimeout(() => retry_attempts--, 60000);
          return firebase_auth.currentUser.getIdToken(true).then((res) => {
            app.$api.defaults.headers.common['Authorization'] = `Bearer ${res}`;
            return app.$api.request(originalRequestConfig).then((res) => {
              console.log('Request erfolgreich durchgeführt');
              return res;
            });
          });
        },
      );
    },
    async login(_, { email, password } = {}) {
      return signInWithEmailAndPassword(firebase_auth, email, password);
    },
    async logout(_) {
      return firebase_auth.signOut();
    },
    async changePW(_, { old_password, new_password } = {}) {
      await reauthenticateWithCredential(
        firebase_auth.currentUser,
        EmailAuthProvider.credential(
          firebase_auth.currentUser.email,
          old_password,
        ),
      );
      return updatePassword(firebase_auth.currentUser, new_password);
    },
    async updateProfile(_, profile) {
      return updateProfile(firebase_auth.currentUser, profile);
    },
    async resetPWbyEmail(_, email) {
      return sendPasswordResetEmail(firebase_auth, email);
    },
    async updateIntervalHandler(context) {
      // context.state.loaded = false;
      await Promise.all([
        context.dispatch('updateTeamState'),
        context.dispatch('updateApplicationState'),
      ]);
      // .finally(() => context.state.loaded = true);
    },
    // Team Cache
    async updateTeamState(context, { team_id, team_person_id } = {}) {
      context.state.backendUserData = await app.$api
        .get('/user/me')
        .then((res) => res.data);
      context.state.backendUserData.teams = [];
      let team_personen = context.state.backendUserData.team_persons;
      let team_person =
        team_personen.find(
          (tp) =>
            (team_id || team_person_id) &&
            (!team_id || tp.team.id == team_id) &&
            (!team_person_id || tp.id == team_person_id),
        ) ??
        team_personen.find(
          (tp) =>
            localStorage.getItem('selected_team_person_id') &&
            tp.id == localStorage.getItem('selected_team_person_id'),
        ) ??
        context.state.backendUserData.team_persons[0] ??
        null;
      if (!team_person) return context.commit('resetTeamState');
      await Promise.all([
        app.$api
          .get(`/team`, {
            params: { person_user_id: context.state.backendUserData.id },
          })
          .then((res) => (context.state.backendUserData.teams = res.data)),
        app.$api
          .get(`/team/${team_person.team.id}`)
          .then((res) => (context.state.activeTeam = res.data)),
        app.$api
          .get(`/team`, {
            params: {
              customers_for_team_id: team_person.team.id,
            },
          })
          .then((res) => (context.state.activeTeam_customers = res.data)),
      ]);
      context.commit('activeTeam_person', team_person);
    },
    // Catalog Cache
    async initApplicationState(context) {
      if (
        context.state.products &&
        context.state.product_groups &&
        context.state.terms &&
        context.state.articles &&
        context.state.article_companies &&
        context.state.article_categories
      )
        return;
      return context.dispatch('updateApplicationState');
    },
    async updateApplicationState(context) {
      await Promise.all([
        context.dispatch('updateProducts'),
        context.dispatch('updateProductGroups'),
        context.dispatch('updateTerms'),
        context.dispatch('updateArticles'),
        context.dispatch('updateArticleCategories'),
        context.dispatch('updateArticleCompanies'),
        context.dispatch('updateSettings'),
        context.dispatch('updateFiles'),
      ]);
    },
    async updateSystemData(context) {
      context.state.system_data = await app.$api
        .get(`/`)
        .then((res) => res.data)
        .catch((err) => {
          console.error(err);
          if (err.response.status == 404) return null;
          setTimeout();
        });
    },
    async updateProducts(context) {
      context.state.products = await app.$api
        .get('/product')
        .then((res) => res.data);
    },
    async updateProductGroups(context) {
      context.state.product_groups = await app.$api
        .get('/product_group')
        .then((res) => res.data);
    },
    async updateTerms(context) {
      context.state.terms = await app.$api.get('/term').then((res) => res.data);
    },
    async updateArticles(context) {
      context.state.articles = await app.$api
        .get('/article')
        .then((res) => res.data);
    },
    async updateArticleCategories(context) {
      context.state.article_categories = await app.$api
        .get('/article_category')
        .then((res) => res.data);
    },
    async updateArticleCompanies(context) {
      context.state.article_companies = await app.$api
        .get('/article_company')
        .then((res) => res.data);
    },
    async updateSettings(context) {
      context.state.settings = await app.$api
        .get('/setting')
        .then((res) => res.data);
    },
    async updateFiles(context) {
      context.state.files = await app.$api.get('/file').then((res) => res.data);
    },
  },
  getters: {
    currentUser(state) {
      return state.currentUser;
    },
    backendUserData(state) {
      return state.backendUserData;
    },
    app_status(state) {
      return state.app_status;
    },
    system_data(state) {
      return state.system_data;
    },
    activeTeam(state) {
      return state.activeTeam;
    },
    activeTeam_person(state) {
      return state.activeTeam_person;
    },
    activeTeam_customers(state) {
      return state.activeTeam_customers;
    },
    activeTeam_products(_, getters) {
      return getters.activeTeam && getters.products
        ? getters.products.filter((product) =>
            getters.activeTeam.product_roles.find(
              (pr) => pr.product_id == product.id,
            ),
          )
        : [];
    },
    activeTeam_product_groups(_, getters) {
      return getters.product_groups && getters.activeTeam_products
        ? getters.product_groups.filter((product_group) =>
            getters.activeTeam_products.find(
              (product) => product.product_group_id == product_group.id,
            ),
          )
        : [];
    },
    products(state) {
      return state.products;
    },
    product_groups(state) {
      return state.product_groups;
    },
    terms(state) {
      return state.terms;
    },
    articles(state) {
      return state.articles;
    },
    article_categories(state) {
      return state.article_categories;
    },
    article_companies(state) {
      return state.article_companies;
    },
    settings(state) {
      return state.settings
        ? state.settings.reduce((out, curr) => {
            out[curr.name] = curr.value;
            return out;
          }, {})
        : null;
    },
    files(state) {
      return state.files;
    },
  },
});
