<template>
  <b-container flush class="h-100">
    <b-row align-content="center" class="my-auto mt-3">
      <b-col class="text-center">
        <h3>
          Willkommen auf beim Vertriebspartnernetzwerk der
          {{ system_data.main_team.name }}
        </h3>
        <div class="mb-3">
          Haben Sie Interesse Teil des Vertriebspartnernetzwerkes zu werden?
          <br />
          Dann senden Sie uns eine E-Mail an
          <b-link
            :href="`mailto:${
              system_data.main_team.sales_email ||
              system_data.main_team.email ||
              system_data.main_team.bill_email
            }`"
          >
            {{
              system_data.main_team.sales_email ||
              system_data.main_team.email ||
              system_data.main_team.bill_email
            }} </b-link
          >.
          <br />
          Wir melden uns bei Ihnen.
        </div>
        <h2>Anmeldung</h2>
        <LoginComponent />

        <b-img :src="`/login-footer-${VUE_APP_DESIGN}.jpg`" class="w-100" />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import LoginComponent from '@/components/Login.vue';
import { mapGetters } from 'vuex';
export default {
  components: {
    LoginComponent,
  },
  data: function () {
    return {
      VUE_APP_DESIGN: process.env.VUE_APP_DESIGN,
    };
  },
  computed: {
    ...mapGetters(['system_data']),
  },
};
</script>

<style></style>
