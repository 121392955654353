<template>
  <div class="p-5 text-center">
    <h2>Anwendung nicht vollständig eingerichtet</h2>
    <p>
      Herzlich Willkommen
      <br />
      Das System ist noch nicht vollständig eingerichtet.
    </p>
    <LoginComponent v-if="!backendUserData" />
    <template v-else-if="!backendUserData.isAdmin">
      <p>Bitte loggen Sie sich als Administrator in das System ein.</p>
      <br />
      <b-link @click="$store.dispatch('updateTeamState')">
        Aktualisieren
      </b-link>
      <br />
      <b-link @click="$router.push({ name: 'support' })"> Support </b-link>
      <br />
      <b-link @click="$store.dispatch('logout')"> Abmelden </b-link>
    </template>
    <template v-else-if="!settings.MAIN_TEAM_ID && !person">
      <p>
        Bitte hinterlegen Sie zunächst Ihre Firmendaten um die Einrichtung
        abzuschließen.
      </p>
      <b-form @submit.stop.prevent="savePersonData">
        <b-row>
          <b-col cols="2">
            <b-form-group label="Anrede">
              <b-form-select
                v-model="salutation"
                name="salutation"
                required
                :options="salutation_options"
              />
            </b-form-group>
          </b-col>
          <b-col cols="5">
            <b-form-group label="Vorname">
              <b-form-input
                v-model="first_name"
                name="first_name"
                required
                placeholder="Vorname"
              />
            </b-form-group>
          </b-col>
          <b-col cols="5">
            <b-form-group label="Nachname">
              <b-form-input
                v-model="last_name"
                name="name"
                required
                placeholder="Nachname"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-button
          type="submit"
          block
          variant="outline-primary"
          class="mt-2"
          size="sm"
          :disabled="loading"
        >
          Weiter
        </b-button>
      </b-form>
    </template>
    <template v-else-if="!settings.MAIN_TEAM_ID">
      <p>
        Bitte hinterlegen Sie zunächst Ihre Firmendaten um die Einrichtung
        abzuschließen.
      </p>
      <TeamNew
        class="text-left"
        custom_create_new_text="Firma anlegen"
        @created="handleNewTeam"
      />
    </template>
    <template v-else>
      <p>
        Die Einrichtung ist abgeschlossen. Sie sollten nun zur Hauptseite
        verbunden werden.
      </p>
      <p>Bitte kontaktieren Sie bei Problemen den Administrator.</p>
    </template>
  </div>
</template>

<script>
import TeamNew from '@/components/team/TeamNew.vue';
import { mapGetters } from 'vuex';
import LoginComponent from '@/components/Login.vue';

// TODO
export default {
  name: 'SetupView',
  components: { TeamNew, LoginComponent },
  data() {
    return {
      salutation: null,
      first_name: null,
      last_name: null,
      person: null, // person, which should be add to the default team
    };
  },
  computed: {
    ...mapGetters(['backendUserData', 'settings']),
  },
  methods: {
    savePersonData() {
      this.person = {
        salutation: this.salutation,
        first_name: this.first_name,
        last_name: this.last_name,
      };
    },
    async handleNewTeam(team) {
      await this.$api
        .patch('/setting/MAIN_TEAM_ID', {
          value: team.id,
        })
        .catch((err) => {
          console.log(
            'Main Team could not be saved, we try to remove it again',
          );
          this.$api.delete(`team/${team.id}`);
          throw err;
        });
      await this.$api
        .post(`/team/${team.id}/person`, {
          ...this.person,
          email: this.backendUserData.email,
        })
        .catch((err) => {
          console.log(
            'Person to main team could not be created, we try to remove it again',
          );
          this.$api.delete(`team/${team.id}`);
          throw err;
        });
      await this.$store.dispatch('updateSystemData');
      await this.$store.dispatch('updateSettings');
      if (this.$route.name != 'admin') this.$router.push({ name: 'admin' });
    },
  },
};
</script>

<style></style>
