<template>
  <b-container>
    <b-row class="text-center">
      <b-col>
        <b-icon icon="three-dots" animation="cylon" font-scale="5" />
        <div>
          {{ app_status }}
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters(['app_status']),
  },
};
</script>

<style></style>
