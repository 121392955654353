import Vue from 'vue';
import App from '@/App.vue';
import store from '@/store';
import router from '@/router';
import '@/plugins/bootstrap-vue';
import MoneySpinner from 'v-money-spinner';
Vue.use(MoneySpinner);
import moment from 'moment-timezone';
moment.locale('de');
moment.tz.setDefault('Europe/Berlin'); // ignore any tz behaviors
moment.defaultFormat = moment.HTML5_FMT.DATETIME_LOCAL_SECONDS;

import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';

Vue.use(require('vue-moment'), { moment });

import { VuePlugin } from 'vuera';

Vue.use(VuePlugin);

Vue.config.productionTip = false;

if (process.env.NODE_ENV == 'production')
  Sentry.init({
    Vue,
    dsn: 'https://079989ced80f4d008c1336ed5520453a@o1343684.ingest.sentry.io/6618863',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['tco-vertriebsportal.de', 'portal.tcorobotics.de'],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,
  });

Vue.filter('EUR', function (number) {
  if (typeof number != 'number' && isNaN(parseFloat(number))) return number;
  return Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
  }).format(parseFloat(number));
});
Vue.filter('%', function (number) {
  if (typeof number != 'number' && isNaN(parseFloat(number))) return number;
  return Intl.NumberFormat('de-DE', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(parseFloat(number));
});
Vue.filter(
  'FGErrorFieldCheck',
  function (error, names, { additional_checks } = {}) {
    names = [names].flat().filter((e) => e);
    let messages =
      error && error.message && Array.isArray(error.message)
        ? error.message
        : [];
    additional_checks = [additional_checks]
      .flat()
      .filter((check) => check && check.condition);
    return additional_checks.length != 0 ||
      messages.find((message) =>
        names.find((name) => name == message.split(' ')[0]),
      )
      ? false
      : null;
  },
);
Vue.filter(
  'FGErrorFieldInvalidFeedback',
  function (error, names, { additional_checks } = {}) {
    names = [names].flat().filter((e) => e);
    let messages =
      error && error.message && Array.isArray(error.message)
        ? error.message
        : [];
    additional_checks = [additional_checks]
      .flat()
      .filter((check) => check && check.condition && check.text)
      .map((e) => e.text);
    let out_messages = [
      ...new Set([
        ...additional_checks,
        ...messages
          .filter((message) =>
            names.find((name) => name == message.split(' ')[0]),
          )
          .map((message) => {
            // let name = message.split(" ")[0];
            let part = message.split(' ').slice(1).join(' ');
            if (part.includes('must be a valid')) {
              let type_name = part
                .split(' ')
                .slice(4)
                .join(' ')
                .split('or')
                .join('oder');
              if (type_name == 'phone number') type_name = 'Telefonnummer';
              return `Muss eine gültige ${
                type_name.charAt(0).toUpperCase() + type_name.slice(1)
              } sein.`;
            }
            if (part.includes('must be an')) {
              let type_name = part
                .split(' ')
                .slice(3)
                .join(' ')
                .split('or')
                .join('oder');
              return `Muss eine gültige ${
                type_name.charAt(0).toUpperCase() + type_name.slice(1)
              } sein.`;
            }
            if (part.includes('must be a')) {
              let type_name = part
                .split(' ')
                .slice(3)
                .join(' ')
                .split('or')
                .join('oder');
              return `Muss ein gültiger ${
                type_name.charAt(0).toUpperCase() + type_name.slice(1)
              } sein.`;
            }
            if (part.includes('is too long')) {
              return `Die max. Feldlänge wurde überschritten.`;
            }

            console.error('Unknown error checker for: ' + part);
            return part;
          }),
      ]),
    ];
    if (out_messages.length == 0) return null;
    return out_messages.join(' ');
  },
);

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount('#app');
