var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',[_vm._v("Account nicht aktiv")]),_c('p',[_vm._v(" Dieser Account ist derzeit nicht aktiv, da Sie keinen Zugriff auf einen Teambereich haben. ")]),_c('h5',[_vm._v("Wie erhalte ich Zugriff?")]),_c('p',[_vm._v(" Bitte kontaktieren Sie uns unter "),_c('b-link',{attrs:{"href":`mailto:${
        _vm.system_data.main_team.email ||
        _vm.system_data.main_team.sales_email ||
        _vm.system_data.main_team.bill_email
      }?subject=Kein%20Zugriff%20auf%20Teambereich&body=Guten%20Tag%2C%0Af%C3%BCr%20den%20Nutzeraccount%20mit%20der%20E-Mail%3A%20${
        _vm.$store.getters.currentUser.email
      }%20fehlt%20aktuell%20der%20Zugang%20zu%20einem%20Teambereich.%0A%0AIch%20bitte%20Sie%2C%20dieses%20Anliegen%20zu%20pr%C3%BCfen%20und%20sich%20im%20Anschluss%20zur%C3%BCckzumelden.%0A%0AMit%20freundlichen%20Gr%C3%BC%C3%9Fen%0AIHR%20NAME`}},[_vm._v(" "+_vm._s(_vm.system_data.main_team.email || _vm.system_data.main_team.sales_email || _vm.system_data.main_team.bill_email)+" ")]),_vm._v(" mit Angabe der E-Mail Adresse Ihres Accounts um Ihren Zugang zu überprüfen und diesen Account einem Teambereich zuzuordnen. ")],1),_c('b-link',{on:{"click":function($event){return _vm.$store.dispatch('updateTeamState')}}},[_vm._v(" Aktualisieren ")]),_c('br'),_c('b-link',{on:{"click":function($event){return _vm.$router.push({ name: 'support' })}}},[_vm._v(" Support ")]),_c('br'),_c('b-link',{on:{"click":function($event){return _vm.$store.dispatch('logout')}}},[_vm._v(" Abmelden ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }