import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: () => import('../views/Dashboard.vue'),
  },
  {
    path: '/angebote',
    name: 'activeTeam_projects_as_customer',
    component: () =>
      import('../views/project/activeTeam/ProjectsAsCustomer.vue'),
  },
  {
    path: '/ihre-projekte',
    name: 'activeTeam_projects_as_assigned_team',
    component: () =>
      import('../views/project/activeTeam/ProjectsAsAssignedTeam.vue'),
  },
  {
    path: '/projekte',
    name: 'projects',
    component: () => import('../views/project/Projects.vue'),
  },
  {
    path: '/projekt/neu',
    name: 'project_new',
    component: () => import('../views/project/ProjectNew.vue'),
  },
  {
    path: '/projekt/:id',
    name: 'project',
    component: () => import('../views/project/Project.vue'),
  },
  {
    path: '/kunden',
    name: 'teams_as_customers',
    component: () => import('../views/team/activeTeam/TeamsAsCustomers.vue'),
  },
  {
    path: '/kunde/neu',
    name: 'team_as_customer_new',
    component: () => import('../views/team/activeTeam/TeamAsCustomerNew.vue'),
  },
  {
    path: '/teams',
    name: 'teams',
    component: () => import('../views/team/Teams.vue'),
  },
  {
    path: '/team/neu',
    name: 'team_new',
    component: () => import('../views/team/TeamNew.vue'),
  },
  {
    path: '/team/:id',
    name: 'team',
    component: () => import('../views/team/Team.vue'),
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('../views/AdminDashboard.vue'),
  },
  {
    path: '/produkte',
    name: 'products',
    component: () => import('../views/product/Products.vue'),
  },
  {
    path: '/produkt/neu',
    name: 'product_new',
    component: () => import('../views/product/ProductNew.vue'),
  },
  {
    path: '/produkt/:id',
    name: 'product',
    component: () => import('../views/product/Product.vue'),
  },
  {
    path: '/produkt_bereich',
    name: 'product_groups',
    component: () => import('../views/product_group/ProductGroups.vue'),
  },
  {
    path: '/artikel',
    name: 'articles',
    component: () => import('../views/article/Articles.vue'),
  },
  {
    path: '/artikel/neu',
    name: 'article_new',
    component: () => import('../views/article/ArticleNew.vue'),
  },
  {
    path: '/artikel/:id',
    name: 'article',
    component: () => import('../views/article/Article.vue'),
  },
  {
    path: '/artikel_hersteller',
    name: 'article_companies',
    component: () => import('../views/article_company/ArticleCompanies.vue'),
  },
  {
    path: '/artikel_kategorie',
    name: 'article_categories',
    component: () => import('../views/article_category/ArticleCategories.vue'),
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: () => import('../views/calendar/Calendar.vue'),
  },
  {
    path: '/info',
    name: 'info',
    component: () => import('../views/Info.vue'),
  },
  {
    path: '/support',
    name: 'support',
    component: () => import('../views/Support.vue'),
  },
  {
    path: '/datenschutz',
    name: 'datenschutz',
    component: () => import('../views/Datenschutz.vue'),
  },
  {
    path: '/*',
    redirect: {
      name: 'dashboard',
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
